import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const Bar = styled(FlexContainer)`
  height: ${SIZES['2']};
  width: ${SIZES['72']};
  background-color: ${COLORS.lightGrey};
`;

const Progress = styled.span<{progress: number}>`
  height: 100%;
  display: inherit;
  background-color: ${COLORS.fupaSecondary};
  width: ${props => props.progress}%;
  max-width: 100%;
`;

interface ProgressBarProps {
  progress: number;
}

export default function ProgressBar({progress}: ProgressBarProps) {
  return (
    <Bar>
      <Progress data-testid='progress-bar' progress={progress} />
    </Bar>
  );
}

export {Progress};
