export enum MatchFlag {
  Ticker = 'ticker',
  Softticker = 'softticker',
  Penalty = 'penalty',
  Overtime = 'overtime',
  Verdict = 'verdict',
  Aborted = 'aborted',
  Cancelled = 'cancelled',
}

export type MatchFlags = MatchFlag[] | null;
