import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import is from '@sindresorhus/is';
import MatchRowTeaserProgressBar from 'app/components/matchRow/teaser/MatchRowTeaserProgressBar';
import {MatchResultModel} from 'app/models/match/MatchResultModel';

const Container = styled.div`
  position: relative;
  display: flex;
  max-width: ${SIZES['46']};
  width: ${SIZES['46']};
  justify-content: space-between;
`;

const Result = styled.div<{color?: string}>`
  border-radius: ${SIZES['4']};
  justify-content: center;
  max-width: 1.375rem;
  width: 1.375rem;
  height: ${SIZES['30']};
  text-align: center;
  background-color: ${props => props.color ?? COLORS.lighterGrey};
`;

enum ResultTypographyVariant {
  RESULT = 'result',
  RESULT_RUNNING = 'result-running',
}

const FallbackGoal = '-';

interface MatchResultProps {
  matchResult: MatchResultModel;
  allowLive: boolean;
  color?: string;
  className?: string;
}

const MatchResult = ({matchResult, color, className, allowLive}: MatchResultProps) => {
  const isLive = useMemo(() => allowLive && matchResult.isLive(), [allowLive, matchResult]);
  const progress = useMemo(() => (isLive ? matchResult.getLiveProgress() : undefined), [isLive, matchResult]);
  const showProgressBar = isLive && is.number(progress) ? <MatchRowTeaserProgressBar progress={progress} /> : null;

  const getGoal = useCallback(
    (goal: number | null) => (
      <Result color={color}>
        <Typography variant={isLive ? ResultTypographyVariant.RESULT_RUNNING : ResultTypographyVariant.RESULT}>
          {goal ?? FallbackGoal}
        </Typography>
      </Result>
    ),
    [color, isLive]
  );

  return (
    <Container className={className}>
      {getGoal(matchResult.result.homeGoal)}
      {getGoal(matchResult.result.awayGoal)}
      {showProgressBar}
    </Container>
  );
};

export default MatchResult;
