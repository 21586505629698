import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {Progress} from 'app/components/match/ProgressBar';

const Wrapper = styled.div`
  display: flex;
  height: ${SIZES['2']};
  width: ${SIZES['44']};
  background-color: ${COLORS.lightGrey};
  position: absolute;
  bottom: 0;
`;

interface ProgressBarProps {
  progress: number;
}

export default function MatchRowTeaserProgressBar({progress}: ProgressBarProps) {
  return (
    <Wrapper>
      <Progress data-testid='progress-bar' progress={progress} />
    </Wrapper>
  );
}
